import React, { useContext, useState, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { AppBar, Button, Card, Tab, Tabs } from "@material-ui/core";

import createuser1 from "../../assets/KnowledgeBase/createuser1.png";
import createuser2 from "../../assets/KnowledgeBase/createuser2.png";
import createuser3 from "../../assets/KnowledgeBase/createuser3.png";
import queues1 from "../../assets/KnowledgeBase/queues1.png";
import queues2 from "../../assets/KnowledgeBase/queues2.png";
import queues3 from "../../assets/KnowledgeBase/queues3.png";
import queues5 from "../../assets/KnowledgeBase/queues5.png";
import schedulemessage2 from "../../assets/KnowledgeBase/schedulemessage2.png";
import schedulemessage3 from "../../assets/KnowledgeBase/schedulemessage3.png";
import transferticket2 from "../../assets/KnowledgeBase/transferticket2.png";
import transferticket3 from "../../assets/KnowledgeBase/transferticket3.png";
import transferticket4 from "../../assets/KnowledgeBase/transferticket4.png";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";

import TabPanel from "../../components/TabPanel";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerTerms: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerIframe: {
    [theme.breakpoints.between("sm", "md")]: {
      padding: "10px 15px 10px 15px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 0px"
    }
  },
  answerTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "10px 15px 10px 15px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 0px"
    }
  },
  answerText: {
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "10px 15px 10px 15px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 0px"
    }
  },
  iframe: {
    [theme.breakpoints.down("xs")]: {
      height: "165px"
    }
  },
  termsFrame: {
    [theme.breakpoints.between("sm", "md")]: {
      height: "500px"
    },
    [theme.breakpoints.down("xs")]: {
      height: "400px"
    }
  },
  list: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",

  },
  containerList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "10px 15px 10px 15px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 0px"
    }
  }
}));

const KnowledgeBase = () => {
  const classes = useStyles();

  const [tab, setTab] = useState("Questions");

  const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

  const answers = [
    {
      question: 'Como transferir um atendimento?',
      answer: 'Durante uma conversa, é possível transferir um atendimento utilizando o ícone de setas, que estão em direções opostas.\n\nNessa opção, você poderá escolher um novo atendente ou fila para direcionar o atendimento que já está em andamento.',
    },
    {
      question: 'Como agendar uma mensagem?',
      answer: 'O agendamento pode ser feito utilizando o ícone de calendário, que te permitirá definir o contato, mensagem e momento em que essa mensagem será disparada automaticamente pelo ChatWeber.',
    },
    {
      question: 'Meu WhatsApp desconectou, e agora?',
      answer: 'Fique tranquilo! Com o seu usuário admin, navegue para a opção “Conexões” no menu à esquerda e localize a conexão que apresenta a queda.\n\nEntão, clique para gerar um novo QR Code, ou use o botão “Reiniciar Conexões” para ajustar o processo (seus dados, conversas e históricos já salvos não serão perdidos).',
    },
    {
      question: 'Como criar uma resposta rápida?',
      answer: 'Para isso, utilize a opção “Respostas rápidas” no menu à esquerda no ChatWeber. Então, siga clicando em “Adicionar” para criar uma nova resposta.\n\nEm “Atalho”, informe o nome curto que servirá para acionar a resposta rápida, e em “Resposta” insira a mensagem que será enviada. Caso precise de informações personalizadas, utilize as variáveis livremente para compor sua nova mensagem com informações dinâmicas do cliente e conversa.\n\nPor fim, defina o “Global” como o status de uso dessa mensagem, podendo ser “Ativo” ou “Inativo”.',
    },
    {
      question: 'Como enviar uma resposta rápida?',
      answer: 'Durante uma conversa, é possível enviar uma resposta rápida ao digitar uma barra / no campo de digitação de mensagem. Isso fará com que o menu de respostas rápidas disponíveis seja exibido.\n\nPara escolher a mensagem a enviar, basta digitar o atalho dela ou apenas clicar naquela que deseja enviar, e então o conteúdo será preenchido.',
    },
    {
      question: 'Como criar um usuário',
      answer: 'Para adicionar um novo usuário/operador no ChatWeber, acesse a sua conta de nível Admin ou Supervisor para iniciar e siga os passos abaixo:',
      steps: [
        {
          index: 1,
          title: 'No menu à esquerda, clique na opção "Usuários";',
          print: createuser1
        },
        {
          index: 2,
          title: 'Agora, continue clicando em "Adicionar usuário";',
          print: createuser2
        },
        {
          index: 3,
          title: 'Na sequência, preencha os campos de acordo com as informações que esse usuário terá, como Nome, E-mail, senha, Nível de permissão e as filas a que ele terá acesso;',
          print: createuser3,
          width: "100%"
        },
        {
          index: 4,
          title: 'Com tudo pronto, clique no botão "Adicionar" e esse novo usuário já poderá fazer acesso ao ChatWeber com os dados usados no momento da criação da conta.'
        }
      ]
    },
    {
      question: 'Como transferir um chamado',
      answer: 'Ao precisar transferir um chamado para outro departamento, ou até mesmo para um outro operador dentro do mesmo departamento, siga estes passos:',
      steps: [
        {
          index: 1,
          title: 'Acesse o atendimento que deseja transferir;',
        },
        {
          index: 2,
          title: 'No menu de opções localizado na parte superior, clique no ícone de setas;',
          print: transferticket2
        },
        {
          index: 3,
          title: 'No popup que se abrirá, informe o nome do "Operador" que receberá o chamado;',
          print: transferticket3
        },
        {
          index: 4,
          title: 'Caso o chamado deva ser transferido para outro departamento, ou diretamente para a fila sem um operador específico, escolha a opção desejada na opção "Transferir para fila";',
          print: transferticket4
        },
        {
          index: 5,
          title: 'Agora, para completar o processo, clique em "Transferir" e a conversa será imediatamente movida.',
          print: '',
        }
      ]
    },
    {
      question: 'Como agendar uma mensagem',
      answer: 'Através do ChatWeber, é possível programar mensagens para serem enviadas automaticamente pelo sistema em um momento de sua preferência. Para fazer isso, siga estes passos:',
      steps: [
        {
          index: 1,
          title: 'No menu à esquerda, encontre e clique na opção "Agendamentos";',
        },
        {
          index: 2,
          title: 'Na nova página que se abrirá, clique no botão "Novo agendamento" para cadastrar uma nova mensagem;',
          print: schedulemessage2
        },
        {
          index: 3,
          title: 'Para dar sequência, selecione o Contato que receberá a mensagem e continue a preencher o conteúdo da "Mensagem" e a "Data/Horário" em que ela será disparada.',
          print: schedulemessage3
        }
      ]
    },
    {
      question: 'Como adicionar uma fila (departamento)',
      answer: 'Ao adicionar uma nova fila, você pode permitir que os clientes possam ter novas interações com o menu, além de uma melhor organização dos departamentos em que seus operadores atuam. Para adicionar uma nova fila, faça o seguinte:',
      steps: [
        {
          index: 1,
          title: 'No menu à esquerda, localize e clique na opção Filas & ChatBot;',
          print: queues1
        },
        {
          index: 2,
          title: 'Uma nova tela se abrirá, clique em Adicionar fila para seguir;',
          print: queues2
        },
        {
          index: 3,
          title: 'No popup de informações, informe o nome e cor que a nova fila terá;',
          print: queues3
        },
        {
          index: 4,
          title: 'Preencha também o campo mensagem de saudação, informando ali a mensagem que será enviada de imediato assim que o cliente entrar no fluxo dessa fila;',
          print: '',
        },
        {
          index: 5,
          title: 'Na área de opções, você poderá definir quais são as opções que o usuário poderá digitar para dar sequência ao fluxo. Aqui, é possível definir o texto que será mostrado nessa opção e a ação que o fluxo vai tomar de acordo com a opção escolhida;',
          print: queues5
        },
        {
          index: 6,
          title: 'Ao selecionar um tipo de ação (como transferir para um atendente, enviar um texto, mover para outra fila, etc), você também pode adicionar “subopções”, ou seja, permitir que o fluxo siga com mais opções que serão desencadeadas a cada escolha do usuário;',
          print: '',
        },
        {
          index: 7,
          title: 'Para criar as opções “filhas”, ou seja, opções dentro de outras opções do fluxo, clique no ícone de Disquete para dar sequência e novas opções serão mostradas para definir novamente textos e ações para seguir.',
          print: '',
        }
      ]
    },
  ];

  const videos = [
    {
      title: 'Conhecendo a plataforma ChatWeber',
      answer: 'Conheça um pouco mais sobre como funciona e quais são as principais ferramentas disponíveis para uso dentro do ChatWeber para dar o pontapé inicial nos seus atendimentos.',
      frame: {
        src: "https://www.youtube-nocookie.com/embed/raH4aIoQ12Q?si=fNqluiosovstDMLF&amp;controls=0",
        width: "100%",
        height: 550
      }
    },
    {
      title: 'Primeiros passos',
      answer: 'Entenda quais são as primeiras configurações a se fazer dentro do sistema e personalize o ChatWeber de acordo com as necessidades do seu negócio.',
      frame: {
        src: "https://www.youtube-nocookie.com/embed/N8iNTt0m_JU?si=YMZ2E6SetPxdV5bb&amp;controls=0",
        width: "100%",
        height: 550
      }
    },
    {
      title: 'Como realizar atendimentos',
      answer: 'Sendo a parte mais constante no uso do ChatWeber, é muito importante como realizar os atendimentos e quais são as ferramentas disponíveis.',
      frame: {
        src: "https://www.youtube-nocookie.com/embed/RY4w-yPMp-U?si=i8oeVMTmYDMvm9Bx&amp;controls=0",
        width: "100%",
        height: 550
      }
    },
    {
      title: 'Como transferir um atendimento',
      answer: 'Veja como transferir uma conversa entre operadores/departamentos no ChatWeber, para direcionar os clientes aos departamentos corretos..',
      frame: {
        src: "https://www.youtube-nocookie.com/embed/KNpnLCG9Nb8?si=6-t6BL2HBHG0j-yb&amp;controls=0",
        width: "100%",
        height: 550
      }
    },
    {
      title: 'Configurando usuários',
      answer: 'Entenda como configurar os usuários dentro do ChatWeber, definindo permissões de acesso e funcionalidades disponíveis para cada usuário.',
      frame: {
        src: "https://www.youtube-nocookie.com/embed/_IirUROLk1Y?si=JAzXeN6s84sgdEOD&amp;controls=0",
        width: "100%",
        height: 550
      }
    }
  ];

  const constructSteps = (steps) => {
    const stepsStructure = steps.map((step) => (
      <>
        <div className={classes.containerList}>
          <div className={classes.list}>
            <strong>{step.index}.</strong>
            <p className={classes.answerText}>{step.title}</p>
          </div>
          {step?.print && <img src={step?.print} width={step?.width} />}
        </div>
      </>
    ));
    return stepsStructure;
  }

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} className={classes.container}>
          <AppBar position="static">
            <Tabs
              value={tab}
              onChange={handleChangeTab}                
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab value="Questions" label={i18n.t("knowledgeBase.tabs.questions")} />
              <Tab value="Videos" label={i18n.t("knowledgeBase.tabs.videos")} />
              <Tab value="Termos" label={i18n.t("knowledgeBase.tabs.terms")} />
            </Tabs>
          </AppBar>
          <TabPanel
            className={classes.container}
            value={tab}
            name={"Questions"}
          >
            <Container width="100%" className={classes.container}>
              <Grid container width="100%">
                {/* INFO DOS USUARIOS */}
                <Grid item xs={12}>
                  {answers.map((faq, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <span className={classes.answerTitle}>{faq.question}</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className={classes.answerText}>{faq.answer}</p>
                        {faq?.steps && constructSteps(faq?.steps)}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              </Grid>
            </Container>
          </TabPanel>
          <TabPanel
            className={classes.container}
            value={tab}
            name={"Videos"}
          >
            <Container width="100%" className={classes.container}>
                <Grid container width="100%">
                    {/* INFO DOS USUARIOS */}
                    <Grid item xs={12}>
                        {videos.map((video, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                  <span className={classes.answerTitle}>{video.title}</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className={classes.answerText}>{video.answer}</p>
                                    <div className={classes.containerIframe}>
                                      <iframe className={classes.iframe} src={video.frame.src} height={video.frame.height} width={video.frame.width} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
          </TabPanel>
          <TabPanel
            className={[classes.containerTerms]}
            value={tab}
            name={"Termos"}
          >
            <Container width="100%" className={[classes.container]}>
              <Grid container width="100%">
                <Grid item xs={12}>
                  <iframe className={classes.termsFrame} src="https://agenciaweber.com.br/arquivos/termos/chatweber.pdf" width="100%" height="500px" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Grid>
              </Grid>
            </Container>
          </TabPanel>
        </Grid>
      </Container >
    </div >
  );
};

export default KnowledgeBase;
